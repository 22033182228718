import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const PatientsTableMetadata: TableHeader[] = [
  {
    label: "",
    value: "avatar_original_url",
    fieldComponent: TableFieldType.avatar,
    class: "avatar",
    sortable: false
  },
  {
    value: "first_name/j/last_name",
    label: "PATIENT NAME",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "id_number",
    label: "ID",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "phone",
    label: "PHONE",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const CaregiverTableMetadata: TableHeader[] = [
  {
    label: "",
    value: "avatar_original_url",
    fieldComponent: TableFieldType.avatar,
    class: "avatar",
    sortable: false
  },
  {
    value: "first_name/j/last_name",
    label: "CAREGIVER NAME",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "id_number",
    label: "ID",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "phone",
    label: "PHONE",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const CustomerCheckInTableMetadata: TableHeader[] = [
  {
    label: "",
    value: "notes",
    fieldComponent: TableFieldType.note
  },
  {
    label: "Serv.#",
    value: "serving_number.prefix/j/serving_number.number",
    fieldComponent: TableFieldType.customIndex,
    sortable: true
  },
  {
    label: "",
    value: "avatar_thumb_url",
    fieldComponent: TableFieldType.image,
    class: "tdt__headers__fieldImage"
  },
  {
    label: i18n.t("customer.status").toString(),
    value: "serving_number.status",
    fieldComponent: TableFieldType.statusCheckin,
    sortable: true
  },
  {
    label: i18n.t("customer.first_name").toString(),
    value: "first_name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("customer.last_name").toString(),
    value: "last_name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("customer.birth_date").toString(),
    value: "birthday",
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("customer.time_in_store").toString(),
    value: "serving_number.time_in_store",
    fieldComponent: TableFieldType.timeInStore,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("customer.limits").toString(),
    value: "limits/p/0",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    sortable: false,
    options: {
      availableBased: false
    }
  }
];

export const CustomerPosTableMetadata: TableHeader[] = [
  {
    label: "",
    value: "notes",
    fieldComponent: TableFieldType.note
  },
  {
    label: "Serv.#",
    value: "serving_number.prefix/j/serving_number.number",
    fieldComponent: TableFieldType.customIndex
  },
  {
    label: "",
    value: "avatar_thumb_url",
    fieldComponent: TableFieldType.image,
    class: "tdt__headers__fieldImage"
  },
  {
    label: "Status",
    value: "serving_number.status",
    fieldComponent: TableFieldType.statusCheckin
  },
  {
    label: "Name",
    value: "first_name/j/last_name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: "",
    value: "limits/p/4",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: true,
    descending: false
  },
  {
    label: "",
    value: "limits/p/3",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: true,
    descending: false
  },
  {
    label: "",
    value: "limits/p/2",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: true,
    descending: false
  },
  {
    label: "",
    value: "limits/p/1",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: true,
    descending: false
  },
  {
    label: "",
    value: "limits/p/0",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: true,
    descending: false
  }
];

export const CustomerTableMetadata: TableHeader[] = [
  {
    label: i18n.t("customer.first_name").toString(),
    value: "first_name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("customer.last_name").toString(),
    value: "last_name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("customer.birth_date").toString(),
    value: "birthday",
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("phone").toString(),
    value: "phone",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("customer.limits").toString(),
    value: "limits/p/0",
    fieldComponent: TableFieldType.limit,
    class: "tdt__headers__fieldLong",
    options: {
      availableBased: false
    },
    sortable: false,
    descending: false
  }
];

export const CustomerTableManagementMetadata: TableHeader[] = [
  {
    label: "Details",
    value: "avatar.avatar_thumb_url",
    fieldComponent: TableFieldType.image,
    class: "tdt__headers__fieldImage"
  },
  {
    label: "First Name",
    value: "first_name",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "primary-text",
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: "Last Name",
    value: "last_name",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "primary-text",
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: "Birth Date",
    value: "birthday",
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true,
    descending: false
  },
  {
    label: "Phone",
    value: "phone",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];
export const CustomerProfileMetadata: TableHeader[] = [
  {
    label: i18n.t("customer.date").toString(),
    value: "created_at",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.fnsDate
  },
  {
    label: i18n.t("action").toString(),
    value: "action_description",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("user").toString(),
    value: "user.full_name",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("location").toString(),
    value: "location.name",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("customer.point").toString(),
    value: "points",
    conditionalClassFn: arg => {
      return Math.sign(arg.points) === 1 ? "pos-value" : "neg-value";
    },
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("customer.amount_associated").toString(),
    value: "amount_associated",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency
  },
  {
    label: i18n.t("customer.total_points").toString(),
    value: "total_points",
    conditionalClassFn: arg => {
      return "tdt__headers__fieldLong total-points";
    },
    fieldComponent: TableFieldType.string
  }
];
