import { Location } from "@/interfaces/location";
import { EventBus } from "@/internal";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { customerService } from "@/services/customer.service";
import HttpService from "@/services/http.service";
import { labResultsService } from "@/services/labResults.service";
import { productService } from "@/services/product.service";
import { productCategoryService } from "@/services/productCategory.service";
import { strainService } from "@/services/strain.service";
import { vendorService } from "@/services/vendor.service";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  fnsUtcToZonedTime
} from "@/utils/date-fns.utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./ChoiceModal.template.vue";

@Component({
  mixins: [Template]
})
export default class ChoiceModal extends Vue {
  @Prop({ required: true })
  public csvType!: string;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  public modalData: object = { width: "578px" };
  public loading = false;

  protected csvTypeServices: { [key: string]: HttpService } = {
    "batch-types": batchTypeService,
    "product-categories": productCategoryService,
    brands: brandService,
    strains: strainService,
    "vendors-laboratories": vendorService,
    products: productService,
    customers: customerService,
    "lab-results": labResultsService
  };

  protected goTo(): void {
    this.$router.push({
      name: "import-wizard",
      params: { type: this.csvType }
    });
    this.closeModal();
  }

  protected async downloadCsv() {
    this.loading = true;
    const csv = await this.csvTypeServices[this.csvType].callCSV("csv_export");
    if (!csv) {
      this.loading = false;
      return;
    }

    const filename = `${this.csvType}-${fnsFormatDate(
      fnsUtcToZonedTime(new Date()),
      FNS_DATE_FORMATS.FILE
    )}.csv`;

    const encoded = !csv.match(/^data:text\/csv/i)
      ? encodeURIComponent("\uFEFF" + csv)
      : csv;

    const link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encoded);
    link.setAttribute("download", filename);
    link.click();
    setTimeout(() => {
      this.loading = false;
      this.$emit("resolve");
      EventBus.$emit("closeModal");
    }, 100);
  }

  protected closeModal() {
    this.$emit("reject");
    EventBus.$emit("closeModal");
  }
}
