import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { HttpQuery } from "@/interfaces/httpQuery";
import { pusherEvents } from "@/internal";
import { CustomerTableManagementMetadata } from "@/metadata/customer";
import { customerActionService } from "@/services/customer.action.service";
import { customerService } from "@/services/customer.service";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { BooleanCheck } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ListManagement.template.vue";
import { ListManagementFiltersComponent } from "./ListManagementFilters.component";
const namespace: string = "CustomerModule";
@Component({
  components: {
    TableComponent,
    ListManagementFiltersComponent
  },
  mixins: [Template],
  inject: ["$changes"]
})
@ResetService(customerService)
export default class ListManagementComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("customers", { namespace })
  public customers!: Customer[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Action("searchToCustomerList", { namespace: "CustomerModule" })
  public searchCustomerAction!: Callback;
  @Action("configService", { namespace })
  public configService!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public first: boolean = true;
  public searchValue: string = "";
  public paginate = customerService.paginationAction();
  protected rowActions: TableAction[] = [];
  protected generalActions: ActionSubheader[] = customerActionService.managementAction(
    this.openCsvModal,
    this.onCustomerSearch,
    this.toggleFilterActive
  );
  protected headers: TableHeader[] = CustomerTableManagementMetadata;
  protected showFilters = false;
  protected activeFilter = false;
  protected query: HttpQuery = this.setCustomerQuery();

  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "customers" }
    );
  }

  public onCustomerSearch(value: string): void {
    this.searchValue = value;
    this.searchCustomerAction(this.searchValue);
  }

  public toggleFilterActive(): void {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public filter(header: TableHeader) {
    customerService.sortQuery(header);
  }

  public onCustomerFilter(filter: { active: boolean; details: boolean }) {
    this.activeFilter = filter.active;
    if (filter.active) {
      this.query["q[is_active]"] = 1;
      delete this.query["q[is_active_is_false]"];
    } else {
      this.query["q[is_active_is_false]"] = 1;
      delete this.query["q[is_active]"];
    }
    if (filter.details) {
      this.query["q[missing_details_is_true]"] = 1;
    } else {
      delete this.query["q[missing_details_is_true]"];
    }
    this.query.page = 1;
    this.triggerSearch(this.query);
  }

  public async triggerSearch(query?: HttpQuery) {
    customerService.setQuery(query);
    this.searchCustomerAction(this.searchValue);
  }

  protected mounted() {
    const viewPermission = this.hasPermission(policyList.viewCustomersList);
    const editPermission = this.hasPermission(
      policyList.modifyCustomerProfileDetails
    );
    this.rowActions = customerActionService.getCustomerManagementActions(
      viewPermission,
      editPermission
    );
    this.setPageNav({
      title: "customer.customer_profile_management",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.configService("customers-management");
    this.triggerSearch();
    this.$changes.watch([pusherEvents.customer], this.searchCustomerAction);
  }

  private setCustomerQuery(): HttpQuery {
    return {
      ...INITIAL_QUERY_STATE,
      // embed: "batchType,category,batches",
      "q[is_active]": 1
    };
  }
}
