import HttpService from "@/services/http.service";
import { createDecorator } from "vue-class-component";

export const ResetService = (service: HttpService | HttpService[]) =>
  createDecorator(comp => {
    const oldDestroyed = comp.destroyed;

    const reset = (isDestroy: boolean) => {
      if (oldDestroyed) {
        oldDestroyed();
      }
      if (Array.isArray(service)) {
        service.forEach(s => {
          serviceResetAction(s, isDestroy);
        });
      } else {
        serviceResetAction(service, isDestroy);
      }
    };

    const serviceResetAction = (s: HttpService, isDestroy: boolean) => {
      s.resetPagination();
      s.resetQuery();
      if (s.cancelRequest && isDestroy) {
        s.cancelRequest();
      }
    };
    const oldBeforeDestroy = comp.beforeDestroy || new Function();
    const oldBeforeCreate = comp.beforeCreate || new Function();
    comp.beforeDestroy = function() {
      oldBeforeDestroy.apply(this);
      reset(true);
    };
    comp.beforeCreate = function() {
      oldBeforeCreate.apply(this);
      reset(false);
    };
  });
